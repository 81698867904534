import React, { useEffect, useState } from 'react';
import useGet from '../../hooks/useGet';
import { HeaderContainer, NavigationWrapper, StatsWrapper } from './header.styles';
import Logo from '../Logo/Logo';
import logoToa from "../../assets/images/logoToa.png";

import NavbarBottom from '../NavbarBottom/NavbarBottom';
import NavbarTopBtn from '../NavbarTopBtn/NavbarTopBtn';
import NavbarButton from '../NavbarButton/NavbarButton';
import { NavbarButtonContainer, NavigationMobileContainer } from '../Navigation/navigation.styles';
import { closeNavIcon, openNavIcon } from '../../constants/icons';
import BuyBtn from '../buy/BuyBtn';

export default function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { data } = useGet('/price/latest/v1?asset=TAO');
    const dataPrice = data?.data[0];

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleSidebar = () => setIsOpen((prev) => !prev);

    const renderNavigation = () => {
        if (windowWidth <= 1048) {
            return (
                <NavigationMobileContainer isOpen={isOpen}>
                    {isOpen && (
                        <>
                            <div style={{
                                display: 'flex', alignItems: "center"
                            }}>
                                <img src={logoToa} alt="logoToa" width={"40px"} />
                                <h3> {parseFloat(dataPrice?.price || 0).toFixed(2)} $</h3>
                            </div>
                            <h3>
                                <span style={{ color: 'gray' }}>Market Cap</span> {parseFloat((dataPrice?.market_cap || 0) / Math.pow(10, 9)).toFixed(2)} B
                            </h3>
                            <h3>
                                <span style={{ color: 'gray' }}>24hr Volume</span> {parseFloat((dataPrice?.volume_24h || 0) / Math.pow(10, 6)).toFixed(2)} M
                            </h3>
                            <NavbarBottom windowWidth={windowWidth} toggleSidebar={toggleSidebar} />
                            <NavbarTopBtn windowWidth={windowWidth} />
                        </>
                    )}
                </NavigationMobileContainer>
            );
        }
        return (
            <NavigationWrapper>
                <NavbarBottom windowWidth={windowWidth} toggleSidebar={toggleSidebar} />
                <NavbarTopBtn windowWidth={windowWidth} />
                <BuyBtn></BuyBtn>
            </NavigationWrapper>
        );
    };
    return (
        <HeaderContainer>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                <Logo />
                {windowWidth <= 1048 && (
                    <NavbarButtonContainer>
                        <NavbarButton icon={isOpen ? closeNavIcon.icon : openNavIcon.icon} toggleSidebar={toggleSidebar} />
                    </NavbarButtonContainer>
                )}
                {renderNavigation()}
            </div>
            {
                windowWidth > 1048 && <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: "center", marginRight: '20px' }}>
                    <StatsWrapper>
                        <div style={{
                            display: 'flex', alignItems: "center"
                        }}>
                            <img src={logoToa} alt="logoToa" width={"40px"} />
                            <h3> {parseFloat(dataPrice?.price || 0).toFixed(2)} $</h3>
                        </div>
                        <h3>
                            <span>Market Cap</span> {parseFloat((dataPrice?.market_cap || 0) / Math.pow(10, 9)).toFixed(2)} B
                        </h3>
                        <h3>
                            <span>24hr Volume</span> {parseFloat((dataPrice?.volume_24h || 0) / Math.pow(10, 6)).toFixed(2)} M
                        </h3>
                    </StatsWrapper>




                </div>}
        </HeaderContainer>
    );
}
