import { useState, useEffect } from 'react';
import axios from 'axios';

const apiKey = process.env.REACT_APP_API_KEY;

axios.defaults.baseURL = 'https://api.taostats.io/api';
axios.defaults.headers.common['Authorization'] = apiKey;

const useGet = (endpoint) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async (retryCount = 0) => {
    try {
      setLoading(true);
      const response = await axios.get(endpoint);
      setData(response.data);
      setError(null); // Clear previous errors
    } catch (err) {
      if (err.response?.status === 429 && retryCount < 5) {
        const retryAfter =
          parseInt(err.response.headers['retry-after'], 10) || 2 ** retryCount; // Exponential backoff
        console.warn(`Rate limited. Retrying in ${retryAfter} seconds...`);
        setTimeout(() => fetchData(retryCount + 1), retryAfter * 1000);
      } else {
        setError(err);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [endpoint]);

  return { data, error, loading };
};

export default useGet;
