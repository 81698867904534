import React, { useState, useEffect } from 'react'
import { NavbarBottomContainer, NavbarPagesLinks, NavbarPagesLinksMobile } from './navbarBottom.styles'
import PagesLinksList from '../PagesLinksList/PagesLinksList'
import DropDownLinks from '../DropDownLinks/DropDownLinks';
import useGet from '../../hooks/useGet';
import useSubnetData from '../../store';
import DropDownLinksV from '../DropDownLinks/DropDownLinksV';

export default function NavbarBottom({ windowWidth, toggleSidebar }) {
  const [allSubnets, setAllSubnets] = useState([]);
  const [allValidators, setAllValidators] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [validatorPage, setValidatorPage] = useState(1);
  const [openSubnets , setOpenSubnets] = useState(false);
  const [openValidators , setOpenValidators] = useState(false);
  const { data, loading, error } = useGet(`subnet/description/v1?page=${currentPage}`);
  const {
    data: validatorData,
    loading: validatorLoading,
    error: validatorError
  } = useGet(`validator/identity/v1?page=${validatorPage}`);

  const { subnetData, setSubnetData, clearSubnetData } = useSubnetData();

  // Subnet data effect
  useEffect(() => {
    if (currentPage === 1) {
      clearSubnetData();
    }
  }, []);

  useEffect(() => {
    if (data && data.data && data.data.length > 0) {
      const newSubnets = data.data;
      setAllSubnets(prevSubnets => [...prevSubnets, ...newSubnets]);
      setSubnetData(newSubnets);

      if (data.data.length > 0) {
        setCurrentPage(prevPage => prevPage + 1);
      }
    }
  }, [data]);

  // Validator data effect
  useEffect(() => {
    if (validatorPage === 1) {
      setAllValidators([]);
    }
  }, []);

  useEffect(() => {
    if (validatorData && validatorData.data && validatorData.data.length > 0) {

      const newValidators = validatorData.data;

      setAllValidators(prevValidators => [...prevValidators, ...newValidators]);
     
      if (validatorData.data.length > 0) {
        setValidatorPage(prevPage => prevPage + 1);
      }
    }
  }, [validatorData]);

  // Error handling
  if (error) {
    console.error("Error fetching subnets:", error);
  }

  if (validatorError) {
    console.error("Error fetching validators:", validatorError);
  }

  // Extract names for dropdown
  const validatorKeys = allValidators.map(subnet => subnet.hotkey.ss58);

  const validatorsUrl = allValidators.map(validator =>
    validator.name || validator.url || 'Unknown Validator'
  )

  return (
    <NavbarBottomContainer windowWidth={windowWidth}>
      {windowWidth <= 1048 ?
        <NavbarPagesLinksMobile>
          <PagesLinksList toggleSidebar={toggleSidebar} />
          <DropDownLinks isOpen={openSubnets} setIsOpen={setOpenSubnets} setClose={setOpenValidators} title="Subnets" />
          <DropDownLinksV isOpen={openValidators} setIsOpen={setOpenValidators} setClose={setOpenSubnets} title="Validators" data={validatorsUrl} keys={validatorKeys} />
        </NavbarPagesLinksMobile> :
        <NavbarPagesLinks>
          <PagesLinksList toggleSidebar={toggleSidebar} />
          <DropDownLinks isOpen={openSubnets} setIsOpen={setOpenSubnets} setClose={setOpenValidators} title="Subnets" />
          <DropDownLinksV isOpen={openValidators} setIsOpen={setOpenValidators} setClose={setOpenSubnets} title="Validators" data={validatorsUrl} keys={validatorKeys} />
        </NavbarPagesLinks>}
    </NavbarBottomContainer>
  );
}
