import React, { useState } from 'react';
import { Rewards, RewardsCard, RewardsInput } from './checkRewards.styles';
import { Button, ParagraphTitle, Title } from '../../styles/style';
import useGet from '../../hooks/useGet';
import "./CheckRewards.css";

export default function CheckRewards() {
  const [wallet, setWallet] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const { data, loading, error } = useGet(isSearching ? `/account/latest/v1?address=${wallet}` : null);
  const { data: pricedata } = useGet('/price/latest/v1?asset=TAO');
  const accountData = data?.data?.[0];

  const formatValue = (value, decimals = 8) => {
    return value ? (parseFloat(value) / 1e9).toFixed(decimals) : '0';
  };

  const calculateUsdValue = (value) => {
    const price = pricedata?.data?.[0]?.price || 0;
    return (value * price).toFixed(2);
  };

  const handleSearch = () => {
    if (wallet.trim()) {
      setIsSearching(true);
    }
  };

  const balance = accountData ? formatValue(accountData.balance_total) : '0';
  const usdValue = accountData ? calculateUsdValue(balance) : '0.00';
  const stakedAmount = accountData ? formatValue(accountData.balance_staked) : '0';
  const stakedUsd = accountData ? calculateUsdValue(stakedAmount) : '0.00';
  const freeAmount = accountData ? formatValue(accountData.balance_free) : '0';
  const freeUsd = accountData ? calculateUsdValue(freeAmount) : '0.00';
  const createdDate = accountData?.created_on_date || '';
  const rank = accountData?.rank || '0';
  const blockNumber = accountData?.block_number || '0';

  return (
    <Rewards>
      <RewardsCard>
        <Title>
          <strong>Monthly Staking Rewards</strong>
        </Title>
        <ParagraphTitle>
          <strong>Check your monthly $TAO staking rewards.</strong>
        </ParagraphTitle>
        <div className="form-group">
          <RewardsInput
            type="text"
            id="tao_wallet"
            name="tao_wallet"
            required
            placeholder="Enter your TAO staking wallet address"
            value={wallet}
            onChange={(e) => {
              setWallet(e.target.value);
              setIsSearching(false); // Reset search when input changes
            }}
          />
        </div>
        <div className="button-area m-t-1">
          <Button onClick={handleSearch} disabled={loading || !wallet.trim()}>
            <span className="button-text">
              {loading ? 'Loading...' : 'Search →'}
            </span>
          </Button>
        </div>
        
      </RewardsCard>

      {isSearching && (
        <RewardsCard>
          <div className="stats-card">
            { accountData ? (
              <div className="balance-display">
                <div className="last-updated">Last Updated At Block: {blockNumber}</div>
                <div className="balance-header">
                  <span>Balance</span>
                </div>
                <div className="balance-amount">
                  {balance} <span className="balance-unit">τ</span>
                </div>
                <div className="usd-value">≈ ${usdValue} USD</div>
                <div className="stats-grid">
                  <div className="stat-box">
                    <div className="stat-label">Staked</div>
                    <div className="stat-value">{stakedAmount} τ</div>
                    <div className="stat-secondary">≈ ${stakedUsd} USD</div>
                  </div>
                  <div className="stat-box">
                    <div className="stat-label">Free</div>
                    <div className="stat-value">{freeAmount} τ</div>
                    <div className="stat-secondary">≈ ${freeUsd} USD</div>
                  </div>
                  <div className="stat-box">
                    <div className="stat-label">Created On</div>
                    <div className="stat-value">{createdDate}</div>
                    <div className="stat-secondary nakamoto-chain">Nakamoto Chain</div>
                  </div>
                  <div className="stat-box">
                    <div className="stat-label">Rank</div>
                    <div className="stat-value">{rank}</div>
                  </div>
                </div>
              </div>
            ) : (
              <div>No account data available for this address</div>
            )}
          </div>
        </RewardsCard>
      )}
    </Rewards>
  );
}
