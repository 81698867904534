import React, { useState } from 'react';
import './buyBtn.css';
import logos from '../../assets/buylogos/LogoExports'
import logoToa from "../../assets/images/logoToaBlue.png";
const BuyBtn = () => {
  const [isOpen, setIsOpen] = useState(false);



  return (
    <div className="dropdown-container">
      <button
        className="dark-button"
        onClick={() => setIsOpen(!isOpen)}
      >
        Buy <span className='logotoa'><img src={logoToa} alt="logoToa" width={"20px"} /></span>
        <span className="chevron">▼</span>
      </button>

      {isOpen && (
        <div className="dropdown-menu">
          {logos.map((logo, index) => (

            <a key={index} className="dropdown-item" href={logo.href}>
              <div style={{ backgroundColor: logo.color }}>
                <img src={logo.src} width={30} alt={logos.name} />
              </div>

              <span className="exchange-name">{logo.name}</span>
            </a>

          ))}
        </div>
      )}
    </div>
  );
};

export default BuyBtn;
