import { styled } from 'styled-components';
import benefitsBg from '../../assets/images/benefits-bg.png';

export const BenefitsSection = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    background-image: url(${benefitsBg});
    background-size: cover;
    background-position: center;
    overflow: hidden;

    @media (max-width: 1024px) {
        min-height: auto;
        padding: 60px 20px;
    }

    @media (max-width: 768px) {
        padding: 40px 15px;
    }
`;

export const BenefitsCardsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    width: 70%;
    margin-top: 5rem;

    @media (max-width: 1200px) {
        width: 85%;
        gap: 3rem;
    }

    @media (max-width: 768px) {
        width: 95%;
        flex-direction: column;
        gap: 2rem;
        margin-top: 3rem;
    }
`;

export const BenefitsCardsText = styled.div`
    display: flex;
    width: 70%;
    margin-top: 5rem;
    
    @media (max-width: 1200px) {
        width: 85%;
    }

    @media (max-width: 768px) {
        width: 95%;
        margin-top: 3rem;
        flex-direction: column;
    }
`;

export const BenefitsCards = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;
    background-color: var(--color-green);
    padding: 2rem;
    border-radius: 15px;
    
    @media (max-width: 768px) {
        gap: 1.5rem;
        padding: 1.5rem;
    }

    @media (max-width: 480px) {
        gap: 1rem;
        padding: 1rem;
    }
`;

export const BenefitsImg = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1.5rem 2rem;
    box-shadow: inset 0px 0px 30px 1px rgba(23,123,217,0.36);
    border-radius: 15px;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.02);
    }

    img {
        width: 400px;
        height: 200px;
        object-fit: cover;
        border-radius: 10px;
    }

    @media (max-width: 1200px) {
        padding: 1.2rem 1.5rem;
        
        img {
            width: 350px;
            height: 175px;
        }
    }

    @media (max-width: 768px) {
        padding: 1rem;
        
        img {
            width: 300px;
            height: 150px;
        }
    }

    @media (max-width: 480px) {
        padding: 0.8rem;
        
        img {
            width: 100%;
            height: auto;
            max-height: 130px;
        }
    }
`;

export const BenefitsWrapper = styled.div`
    margin-top: 1rem;
    padding: 32px 0;

    @media (max-width: 768px) {
        margin-top: 0.5rem;
        padding: 20px 0;
    }

    @media (max-width: 480px) {
        padding: 15px 0;
    }
`;

export const BenefitsText = styled.div`
    padding-top: 1px;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media (max-width: 800px) {
        gap: 1.5rem;
    }

    @media (max-width: 450px) {
        gap: 1rem;
    }
`;

export const BenefitsTitle = styled.h6`
    font-size: 48px;
    font-weight: 600;
    margin-top: 5rem;
    background: ${({ background }) => background || 'linear-gradient(to right, white, #177BD9)'};
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    text-align: center;
    width: 100%;

    @media (max-width: 1200px) {
        font-size: 40px;
        margin-top: 4rem;
    }

    @media (max-width: 800px) {
        font-size: 32px;
        margin-top: 3rem;
    }

    @media (max-width: 450px) {
        font-size: 24px;
        margin-top: 2rem;
    }
`;

export const BenefitsContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    padding: 40px 20px;

    @media (max-width: 1200px) {
        min-height: auto;
        padding: 60px 20px;
    }

    @media (max-width: 768px) {
        padding: 40px 15px;
    }

    @media (max-width: 480px) {
        padding: 30px 10px;
    }
`;