import styled from 'styled-components';
import stakeBg from '../../assets/images/stake-bg.png';

// Media query breakpoints
const breakpoints = {
  mobile: '320px',
  tablet: '768px',
  laptop: '1024px',
  desktop: '1440px'
};

export const StakeSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background-image: url(${stakeBg});
  padding: 2rem;

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
  }
`;

export const StakeContent = styled.div`
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;

  @media (max-width: ${breakpoints.tablet}) {
    padding: 2rem 1rem;
    gap: 1.5rem;
    width: 100%;
  }
`;

export const StakeList = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
`;

export const ListItem = styled.li`
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  overflow: hidden;

  @media (max-width: ${breakpoints.mobile}) {
    padding: 0.75rem;
    gap: 1rem;
  }
`;

export const ExpandableContent = styled.div`
  max-height: ${props => props.open ? '1000px' : '0'};
  overflow: hidden;
  transition: max-height 0.3s ease-out;
`;

export const ListItemTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 0.9rem;
  }
`;

export const DetailsPanel = styled.div`
  border-radius: 8px;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  img {
    width: 100%;
    border-radius: 8px;
    
    @media (max-width: ${breakpoints.tablet}) {
      max-height: 300px;
      object-fit: cover;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    max-width: 100%;
  }
`;

export const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;

  @media (max-width: ${breakpoints.mobile}) {
    margin-bottom: 1.5rem;
  }
`;

export const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  @media (max-width: ${breakpoints.mobile}) {
    margin-bottom: 0.75rem;
    font-size: 0.9rem;
  }
`;

export const Checkmark = styled.span`
  width: 20px;
  height: 20px;
  background-color: #0078FF;
  border-radius: 50%;
  margin-right: 1rem;

  @media (max-width: ${breakpoints.mobile}) {
    width: 16px;
    height: 16px;
    margin-right: 0.75rem;
  }
`;

export const ClusterDetail = styled.div`
  background: #222;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;

  @media (max-width: ${breakpoints.mobile}) {
    padding: 0.75rem;
  }
`;

export const ClusterDetailHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media (max-width: ${breakpoints.mobile}) {
    margin-bottom: 0.75rem;
    font-size: 0.9rem;
  }
`;

export const ClusterDetailBody = styled.div`
  font-size: 0.9rem;
  line-height: 1.5;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 0.85rem;
    line-height: 1.4;
  }
`;
