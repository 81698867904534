import React from "react";
import { IntroductionContainer, IntroductionImg, IntroductionSection } from "./introduction.styles"
import useScrollAnimation from "../../hooks/useScrollAnimation";
import { motion } from "framer-motion";
import {  Paragraph, Title } from "../../styles/style";


export default function Introduction2() {
    const handleCopy = () => {
        navigator.clipboard.writeText('btcli root delegate --delegate_ss58key 5DM7CPqPKtMSADhFKYsstsCS4Tm4Kd6PMXoh6DdqY4MtxmtX');
        alert('Command copied to clipboard!');
    };

    const { myRef, isVisible } = useScrollAnimation('intro-text');
    return (
        <IntroductionSection>
            <IntroductionContainer
                as={motion.div}
                id='intro-text'
                ref={myRef}
                initial={{ opacity: 0, y: 100 }}
                animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                transition={{ duration: 1 }}
            >
                {/* <Title>Leading the Decentralized Revolution</Title> */}
                <Paragraph>My name is Echo, I founded the Cortex Foundation.
                    I mined TAO from the early days and quickly realized that we could change the world and our future with Bittensor. I am a fervent advocate for decentralization, AI, and technology. I fight against validators who copy weights, and I stand for a world of freedom, honesty, and sharing. Long live Bittensor, and let's work towards a peaceful future.
                    
                  
                </Paragraph>
                

            </IntroductionContainer>
        </IntroductionSection>
    )
}