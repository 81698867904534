import React from 'react';
import PropTypes from 'prop-types';

const CalculateBtn = ({ text, color, onClick, hoverColor }) => {
  const buttonStyle = {
    backgroundColor: color,
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'transform 0.3s ease, background-color 0.3s ease',
  };

  const hoverStyle = {
    backgroundColor: hoverColor,
    transform: 'scale(1.05)',
  };

  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <button
      style={isHovered ? { ...buttonStyle, ...hoverStyle } : buttonStyle}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {text}
    </button>
  );
};

CalculateBtn.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func,
  hoverColor: PropTypes.string,
};

CalculateBtn.defaultProps = {
  color: '#007BFF', // Default color is blue
  hoverColor: '#0056b3', // Default hover color is darker blue
  onClick: () => {}, // Default is a no-op function
};

export default CalculateBtn;