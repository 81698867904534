import React from 'react'
import { CortextClusterSection } from './cortextCluster.styles'
import useScrollAnimation from '../../hooks/useScrollAnimation';
import { motion } from 'framer-motion';
import { cortextCluster } from '../../constants/data';
import CortextText from '../CortextText/CortextText';

export default function CortextCluster() {
  const str = `My name is Echo, I founded the Cortex Foundation. I mined TAO from the early days and quickly realized that we could change the world and our future with Bittensor. I am a fervent advocate for decentralization, AI, and technology. I fight against validators who copy weights, and I stand for a world of freedom, honesty, and sharing. Long live Bittensor, and let's work towards a peaceful future. sur Cortex Ecosystem: tu colles ca stp: @everyone 🌟 Big News! 🌟  

We're excited to share that our validator is leading the game with an impressive 19.05% APY in earnings—proving that we're not just competitive, but the #1 non-weight-copier validator out there. 🎉  

On top of that, we've officially registered Subnet 55 (Signal) with <@694517700277567618>, powered by our lightweight framework, Circuit. Circuit makes it easier than ever to kickstart your own subnet quickly and efficiently. 🚀  

And here’s the big reveal: Circuit and Signal will officially go live in production on Monday, November 25, 2024. Stay tuned for amazing projects and updates showcasing the potential of this ecosystem!  

Feel free to delegate and be part of something groundbreaking:  
👉 [Delegate on Taostats](https://delegate.taostats.io/staking?hkey=5DM7CPqPKtMSADhFKYsstsCS4Tm4Kd6PMXoh6DdqY4MtxmtX)

Or delegate directly using:  
btcli root delegate --delegate_ss58key 5DM7CPqPKtMSADhFKYsstsCS4Tm4Kd6PMXoh6DdqY4MtxmtX  

Let’s build the future together! `;
  const { myRef, isVisible } = useScrollAnimation('cortex-cluster');
  return (
    <CortextClusterSection
      as={motion.div}
      id='cortex-cluster'
        ref={myRef}
        initial={{ opacity: 0, y: 100 }}
        animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
        transition={{ duration: 1 }}
    >

        <CortextText cortext={str}/>
    </CortextClusterSection>
  )
}
