import React from 'react'
import { FooterContainer, FooterIcons, FooterLinksPages, FooterContact, FooterSection, InnerFooterContainer, FooterLogo, FooterIconsSecurity, FooterSeparator } from './footer.styles'
import Logo from '../Logo/Logo'
import separator from '../../assets/images/separator.png'
import SocialLinksList from '../SocialLinksList/SocialLinksList'

export default function Footer() {
  return (
    <FooterSection>
      <FooterContainer>

        <FooterContact>
          <FooterLogo>
            <Logo />
         
          </FooterLogo>
        
        </FooterContact>

        <FooterLinksPages>
          <li>
            <a href='/'>Home</a>
          </li>
          <li><a href='/ecosystem'>Ecosystem</a></li>
          <li><a href='/rewards'>Check Rewards</a></li>
         
        </FooterLinksPages>
        <FooterSeparator src={separator} alt='' />
        <FooterIcons>
          <FooterIconsSecurity>
            <li><a href='#'>Terms  </a></li>
            <li><a href='#'>Privacy  </a></li>
            <li><a href='#'>Cookies  </a></li>
          </FooterIconsSecurity>
          <SocialLinksList />
        </FooterIcons>
      </FooterContainer>
    </FooterSection>
  )
}
