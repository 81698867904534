import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useSubnetData from '../../store';
import useGet from '../../hooks/useGet';
import BlockchainStats from '../../components/BlockchainStats/BlockchainStats';
import {
  SubnetDetailContainer,
  SubnetDetailCard,
  SubnetDescription,
  SubnetDetails,
  Breadcrumb,
  NetworkDetails,
  HorizontalDivider
} from './subnetDetail.styles';

const GitHubIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="white"
    style={{ marginRight: '10px' }}
  >
    <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
  </svg>
);

export default function SubnetDetail() {
  const { index } = useParams();
  const { subnetData } = useSubnetData();
  const formatTimestamp = (timestamp) => {
    return timestamp ? new Date(timestamp).toLocaleString() : 'N/A';
  };
  // Always call useGet, even if index is not available
  const { data: apiData, loading, error } = useGet(
    `https://api.taostats.io/api/subnet/latest/v1?netuid=${index}`
  );

  // Convert index to number and find the corresponding subnet
  const subnet = useMemo(() => subnetData[Number(index)], [subnetData, index]);

  if (!subnet) {
    return (
      <SubnetDetailContainer>
        <h1>Subnet Not Found</h1>
        <p>The requested subnet does not exist.</p>
      </SubnetDetailContainer>
    );
  }

  return (
    <SubnetDetailContainer>
      <Breadcrumb>Subnets / {index}</Breadcrumb>
      {loading && <p style={{ color: 'white' }}>Loading...</p>}
      {error && <p style={{ color: 'red' }}>Error: {error.message}</p>}

      <SubnetDetailCard>
        <NetworkDetails>
          <h2>{subnet.bittensor_id}</h2>
          <h3>{subnet.name}</h3>
          {apiData && apiData.data && apiData.data[0] && (
            <p style={{ color: 'white', marginTop: '10px' }}>
              Created: {new Date(apiData.data[0].timestamp).toLocaleString()}
            </p>
          )}
        </NetworkDetails>

        <HorizontalDivider />

        <SubnetDescription>{subnet.description}</SubnetDescription>
        
        {subnet.github && (<>
          <div style={{ color: 'white', display: 'flex', alignItems: 'center' }}>

            <a
              href={subnet.github}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <GitHubIcon />
              {subnet.github}
            </a>
          </div>
          {!subnet.hw_requirements && <HorizontalDivider />}
        </>
        )}


        {subnet.hw_requirements && (
          <>
            <br />
            <p style={{
              color: '#1678D3',
              display: 'flex',
              alignItems: 'center',
              fontWeight: 'bold'
            }}>
              <strong>Hardware Requirements:</strong>{' '}
              <a
                href={subnet.hw_requirements}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: '#1678D3',
                  marginLeft: '10px',
                  textDecoration: 'underline',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                View Requirements

              </a>
            </p>
            <HorizontalDivider />
          </>
        )}



        <SubnetDetails>
          {/* Additional details can be added here */}
        </SubnetDetails>
      </SubnetDetailCard>

      {apiData && apiData.data && apiData.data[0] && (
        <>
          <h2 style={{ color: 'white', marginTop: '20px', marginLeft: '50px' }}>Blockchain Stats</h2>
          <BlockchainStats
            stats={apiData.data[0]}
          />
        </>
      )}
    </SubnetDetailContainer>
  );
}
