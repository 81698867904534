import styled from 'styled-components';

export const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;
  background-color: #111;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
`;

export const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #222;
  padding: 15px;
  border-radius: 6px;
  align-items: center;
  text-align: center;
`;

export const StatLabel = styled.span`
  color: #666;
  font-size: 14px;
  margin-bottom: 5px;
`;

export const StatValue = styled.span`
  color: #fff;
  font-size: 16px;
  font-weight: bold;
`;
