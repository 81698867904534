import styled from 'styled-components';

export const SubnetDetailContainer = styled.div`
  padding: 100px 20px 20px;
  width: 100%;
  max-width: none;
  margin: 0 auto;
  background-color: #000;
  min-height: 100vh;
`;

export const Breadcrumb = styled.div`
  font-size: 14px;
  color: #fff;
  margin-bottom: 20px;
  width: 100%;
`;

export const NetworkDetails = styled.div`
  width: 100%;
  margin-bottom: 20px;
  
  h2 {
    font-size: 24px;
    color: #1678D3;
    margin-bottom: 10px;
  }
  
  h3 {
    font-size: 32px;
    color: #fff;
    margin-bottom: 15px;
  }
  
  p {
    font-size: 16px;
    color: #ccc;
    line-height: 1.6;
  }
`;

export const SubnetDetailCard = styled.div`
  background-color: #111;
  border-radius: 8px;
  padding: 30px;
  width: 100%;
`;

export const SubnetTitle = styled.h2`
  color: #fff;
  margin-bottom: 20px;
  font-size: 2em;
  border-bottom: 2px solid #333;
  padding-bottom: 10px;
`;

export const SubnetDescription = styled.p`
  color: #ccc;
  margin-bottom: 30px;
  line-height: 1.6;
  font-size: 1.1em;
`;

export const SubnetDetails = styled.div`
  width: 100%;
  
  p {
    margin: 15px 0;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 16px;
  }

  strong {
    margin-right: 15px;
    min-width: 200px;
    color: #666;
  }

  a {
    color: #3498db;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #2980b9;
      text-decoration: underline;
    }
  }

  .bittensor-id {
    color: #fff; 
  }
`;

export const HorizontalDivider = styled.hr`
  width: 100%;
  border: none;
  border-top: 1px solid #333;
  margin: 20px 0;
`;
