import styled from "styled-components";

export const BitneuronTokenSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 100%;
    margin-top: 5rem;

    @media (max-width: 768px) {
        gap: 1.5rem;
        margin-top: 3rem;
    }
`

export const BitneuronTokenContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 75%;

    @media (max-width: 768px) {
        width: 90%;
        gap: 1.5rem;
    }
`

export const BitneuronTokenApiContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    width: 75%;

    @media (max-width: 768px) {
        flex-direction: column;
        width: 90%;
        gap: 2rem;
    }
`
