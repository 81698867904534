import { create } from 'zustand';

const useSubnetData = create((set) => ({
  subnetData: [],
  setSubnetData: (newSubnetData) => set((state) => ({
    subnetData: [...state.subnetData, ...newSubnetData]
  })),
  clearSubnetData: () => set({ subnetData: [] })
}));



export default useSubnetData;
