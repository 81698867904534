import React from 'react';
import { 
  StatsContainer, 
  StatItem, 
  StatLabel, 
  StatValue 
} from './BlockchainStats.styles';




export default function BlockchainStats({ stats }) {
  // Convert values to more readable format
  const recycledLifetime = stats.recycled_lifetime 
    ? (Number(stats.recycled_lifetime) / 1e9).toFixed(2) 
    : 'N/A';
  
  const recycled24Hours = stats.recycled_24_hours 
    ? Math.abs(Number(stats.recycled_24_hours) / 1e9).toFixed(2) 
    : 'N/A';
  
  const registrationCost = stats.registration_cost 
    ? Number(stats.registration_cost).toFixed(2) 
    : 'N/A';

  // Calculate emissions percentage
  const emissions = stats.emission 
    ? ((Number(stats.emission) / 1e9) * 100).toFixed(2) + '%'
    : 'N/A';

  return (
    <StatsContainer>
      <StatItem>
        <StatLabel>Emissions</StatLabel>
        <StatValue>{emissions}</StatValue>
      </StatItem>

      <StatItem>
        <StatLabel>Recycled (τ)</StatLabel>
        <StatValue>{recycledLifetime} τ</StatValue>
      </StatItem>

      <StatItem>
        <StatLabel>Recycled (24H) (τ)</StatLabel>
        <StatValue>{recycled24Hours} τ</StatValue>
      </StatItem>

      <StatItem>
        <StatLabel>Registration Cost (τ)</StatLabel>
        <StatValue>{registrationCost} τ</StatValue>
      </StatItem>

      <StatItem>
        <StatLabel>Keys</StatLabel>
        <StatValue>{stats.active_keys}/{stats.max_keys || stats.max_neurons}</StatValue>
      </StatItem>

      <StatItem>
        <StatLabel>Validators</StatLabel>
        <StatValue>{stats.active_validators}/{stats.validators}</StatValue>
      </StatItem>

      <StatItem>
        <StatLabel>Miners</StatLabel>
        <StatValue>{stats.active_miners}/{stats.max_neurons}</StatValue>
      </StatItem>

      <StatItem>
        <StatLabel>Dual Miners/Validators</StatLabel>
        <StatValue>{stats.active_dual}/256</StatValue>
      </StatItem>

      <StatItem>
        <StatLabel>Blocks Until Next Epoch</StatLabel>
        <StatValue>{stats.blocks_until_next_epoch}</StatValue>
      </StatItem>

      <StatItem>
        <StatLabel>Blocks Until Next Adjustment</StatLabel>
        <StatValue>{stats.blocks_until_next_adjustment}</StatValue>
      </StatItem>
    </StatsContainer>
  );
}
