import React from 'react';
import { Link } from 'react-router-dom';
import "./DropDownLinks.css";

const DropDownLinksV = ({ title, data, keys, isOpen, setIsOpen, setClose }) => {
  const handleLinkClick = () => {
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    if (!isOpen) {
      setClose(false); // Close the other dropdown
      setIsOpen(true); // Open this dropdown
    } else {
      setIsOpen(false); // Close this dropdown
    }
  };

  return (
    <div className="dropdown-container">
      <button onClick={toggleDropdown} className="dropdown-button-links">
        {title || "Dropdown"}
        <span className="dropdown-icon">{isOpen ? "▲" : "▼"}</span>
      </button>
      {isOpen && (
        <div className="dropdown-menu-list">
          <div className="dropdown-grid">
            {data.map((item, index) => (
              <Link
                key={keys[index]}
                to={`/validators/${keys[index]}`}
                className="dropdown-item"
                onClick={handleLinkClick}
              >
                {index}: {item}
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDownLinksV;
