import styled from 'styled-components';

export const SubnetDetailContainer = styled.div`
    margin-top: 120px;
    padding: 50px;
    width: 100%;
    max-width: none;
    background-color: #000;
    min-height: 100vh;
    color: white;
`;

export const Breadcrumb = styled.div`
    font-size: 14px;
    color: #4a5568;
    margin-bottom: 20px;
`;

export const SubnetDetailCard = styled.div`
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 30px;
`;

export const SubnetDescription = styled.div`
    margin-bottom: 20px;
    
    h2 {
        color: #2d3748;
        margin-bottom: 10px;
        font-size: 24px;
        font-weight: 600;
    }
    
    p {
        color: #718096;
        font-size: 16px;
    }
`;

export const SubnetDetails = styled.div`
    margin-top: 20px;
`;

export const ValidatorInfoSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const ValidatorStatItem = styled.div`
    padding: 15px;
    background-color: #edf2f7;
    border-radius: 8px;
    margin-bottom: 10px;
    
    strong {
        margin-right: 10px;
        color: #2d3748;
        font-weight: 600;
    }
`;

export const ActionButton = styled.button`
    background-color: #4299e1;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
    font-weight: 500;
    
    &:hover {
        background-color: #3182ce;
    }
`;

export const NetworkDetails = styled.div`
    margin-top: 20px;
`;

export const HorizontalDivider = styled.hr`
    border: none;
    border-top: 1px solid #e2e8f0;
    margin: 20px 0;
`;

export const ValidatorHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
`;

export const ValidatorAvatar = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #000;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-weight: bold;
    color: white;
    border: 2px solid #333;
`;

export const ValidatorInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const ValidatorName = styled.div`
    font-size: 48px;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;

    .verified {
        color: #00ffb3;
        font-size: 24px;
    }
`;

export const ValidatorStats = styled.div`
    color: #666;
    font-size: 14px;
    
    .total-stake {
        color: white;
        font-size: 36px;
        font-weight: 500;
        margin: 8px 0;
    }

    .stake-change {
        background-color: rgba(255, 0, 0, 0.2);
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 12px;
        color: #ff4444;
        display: inline-block;
    }
`;

export const ActionSection = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 20px;
`;

export const DiscoverButton = styled.button`
    background-color: #1a1a1a;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    text-decoration: none;

    &:hover {
        background-color: #333;
        text-decoration: none;
    }

    .arrow {
        margin-left: 4px;
    }
`;

export const DelegateButton = styled.button`
    background-color: white;
    color: black;
    border: none;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;

    &:hover {
        background-color: #e0e0e0;
        text-decoration: none;
    }
`;

export const KeyInfo = styled.div`
    margin-top: 20px;
    color: #666;
    font-size: 14px;

    .label {
        margin-right: 8px;
    }

    .value {
        color: #999;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .copy-icon {
        cursor: pointer;
        opacity: 0.6;
        &:hover {
            opacity: 1;
        }
    }
`;

export const RegisterInfo = styled.div`
    display: flex;
    gap: 20px;
    color: #666;
    font-size: 14px;
    margin-top: 10px;

    .label {
        color: #666;
    }

    .value {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }
`;

export const StatsTable = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
    background: #1a1a1a;
    padding: 20px;
    border-radius: 8px;
`;

export const StatCell = styled.div`
    padding: 15px;
    
    .label {
        color: #718096;
        font-size: 14px;
        margin-bottom: 5px;
    }
    
    .value {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
    }
    
    .change {
        color: ${props => props.isPositive ? '#48bb78' : '#f56565'};
        font-size: 12px;
        margin-left: 8px;
    }
`;

export const CopyTooltip = styled.span`
    position: absolute;
    background-color: #00ffb3;
    color: black;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    top: -25px;
    pointer-events: none;
    opacity: 0.9;
    
    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #00ffb3 transparent transparent transparent;
    }
`;