import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    SubnetDetailContainer,
    ValidatorHeader,
    ValidatorAvatar,
    ValidatorInfo,
    ValidatorName,
    ValidatorStats,
    ActionSection,
    DiscoverButton,
    DelegateButton,
    KeyInfo,
    RegisterInfo,
    CopyTooltip,
    StatsTable,
    StatCell
} from './Validator.styles';
import useGet from '../../hooks/useGet';
import { FiCheck, FiCopy, FiArrowRight } from 'react-icons/fi';

function Validator() {
    const params = useParams();
    const hotkey = params.index;
    const { data: apiData, loading, error } = useGet(
        `/validator/latest/v1?hotkey=${hotkey}`
    );
    const { data: iapiData,error: ierror ,loading: iloading } = useGet(
        `/validator/identity/v1?hotkey=${hotkey}`
    );

    const [validatorData, setValidatorData] = useState(null);
    const [identityData, setIdentityData] = useState(null);
    const [copiedHotkey, setCopiedHotkey] = useState(false);
    const [copiedColdkey, setCopiedColdkey] = useState(false);

    useEffect(() => {
        if (apiData && apiData.data && apiData.data.length > 0) {
            setValidatorData(apiData.data[0]);
        }
    }, [apiData]);

    useEffect(() => {
        if (iapiData && iapiData.data && iapiData.data.length > 0) {
            setIdentityData(iapiData.data[0]);
        }
    }, [iapiData]);

    // Reset copy status after 2 seconds
    useEffect(() => {
        if (copiedHotkey) {
            const timer = setTimeout(() => setCopiedHotkey(false), 2000);
            return () => clearTimeout(timer);
        }
    }, [copiedHotkey]);

    useEffect(() => {
        if (copiedColdkey) {
            const timer = setTimeout(() => setCopiedColdkey(false), 2000);
            return () => clearTimeout(timer);
        }
    }, [copiedColdkey]);

    const handleCopy = async (text, type) => {
        if (!text) return;
        
        try {
            await navigator.clipboard.writeText(text);
            if (type === 'hotkey') {
                setCopiedHotkey(true);
            } else {
                setCopiedColdkey(true);
            }
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };

    if (loading||iloading) return <div style={{ color: 'white' , display: 'flex', justifyContent: 'center', alignItems: 'center' ,height: '100vh' ,width: '100%'}}>Loading...</div>;
    if (error || ierror) return <div style={{ color: 'red' , display: 'flex', justifyContent: 'center', alignItems: 'center' ,height: '100vh' ,width: '100%'}}>{JSON.stringify({error , ierror})}</div>;
    if (!validatorData) return <div style={{ color: 'orange' , display: 'flex', justifyContent: 'center', alignItems: 'center' ,height: '100vh' ,width: '100%'}}>No validator data found</div>;

    const formatNumber = (num) => {
        return new Intl.NumberFormat('en-US', {
            maximumFractionDigits: 2
        }).format(num);
    };

    const formatTao = (tao) => {
        return `${formatNumber(tao / 1000000000)}`;
    };

    const formatPercentage = (value) => {
        return Number(value).toFixed(2);
    };

    const formatNomReturn = (value) => {
        return Number(value / 1000000000).toFixed(3);
    };

    const shortenAddress = (address) => {
        if (!address || typeof address !== 'string') return '';
        return `${address.slice(0, 6)}...${address.slice(-6)}`;
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });
    };

    return (
        <SubnetDetailContainer>
            <ValidatorHeader>
                <ValidatorAvatar>
                    {identityData?.name?.[0]?.toUpperCase() || 'T'}
                </ValidatorAvatar>
                <ValidatorInfo>
                    <ValidatorName>
                        {identityData?.name || validatorData.name}
                        <FiCheck className="verified" />
                    </ValidatorName>
                    <ValidatorStats>
                        Total Stake:
                        <div className="total-stake">
                            {formatTao(validatorData.stake)} τ
                        </div>
                        <div className="stake-change">
                            {formatTao(validatorData.stake_24_hr_change)}
                        </div>
                    </ValidatorStats>
                    <ActionSection>
                        <DiscoverButton
                            as="a"
                            href={identityData?.url || '#'}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ display: identityData?.url ? 'flex' : 'none' }}
                        >
                            Discover {identityData?.name || validatorData.name}
                            <FiArrowRight className="arrow" />
                        </DiscoverButton>
                        <DelegateButton 
                            as="a" 
                            href={`https://delegate.taostats.io/staking?hkey=${validatorData.hotkey}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span>⚡</span> Delegate Stake
                        </DelegateButton>
                    </ActionSection>
                    <KeyInfo>
                        <div style={{ display: 'flex' }}>
                            <span className="label"><span style={{ color: 'white' ,fontWeight : 'bold' }}> Hotkey:</span> {validatorData.hotkey.ss58}</span>
                            <span className="value">
                                {shortenAddress(validatorData?.hotkey)}
                                <span 
                                    onClick={() => handleCopy(validatorData?.hotkey, 'hotkey')}
                                    style={{ 
                                        cursor: 'pointer',
                                        position: 'relative',
                                        display: 'inline-flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    {copiedHotkey ? (
                                        <>
                                            <FiCheck style={{ color: '#00ffb3' }} />
                                            <CopyTooltip>Copied!</CopyTooltip>
                                        </>
                                    ) : (
                                        <FiCopy className="copy-icon" />
                                    )}
                                </span>
                            </span>
                        </div>
                    </KeyInfo>
                    <RegisterInfo>
                        <div>
                            <span className="label">Registered On:</span>
                            <span className="value">{formatDate(validatorData.timestamp)}</span>
                        </div>
                    </RegisterInfo>
                    {identityData?.description && (
                        <ValidatorStats style={{ marginTop: '20px' }}>
                            {identityData.description}
                        </ValidatorStats>
                    )}
                </ValidatorInfo>
            </ValidatorHeader>
            <StatsTable>
            <StatCell>
                    <div className="label">Rank</div>
                    <div className="value">{validatorData.rank}</div>
                </StatCell>
                <StatCell>
                    <div className="label">Delegated</div>
                    <div className="value">{formatTao(parseFloat(validatorData.stake) + parseFloat(validatorData.stake_24_hr_change))} τ</div>
                </StatCell>
                <StatCell>
                    <div className="label">Validator</div>
                    <div className="value">{formatTao(validatorData.validator_stake)} τ</div>
                </StatCell>
                <StatCell>
                    <div className="label">Nominators</div>
                    <div className="value">
                        {validatorData.nominators}
                        <span className="change">
                            {validatorData.nominators_24_hr_change > 0 ? '+' : ''}
                            {validatorData.nominators_24_hr_change}
                        </span>
                    </div>
                </StatCell>
                <StatCell>
                    <div className="label">Dominance</div>
                    <div className="value">{formatPercentage(validatorData.dominance)}%</div>
                </StatCell>
                <StatCell>
                    <div className="label">Total Daily Return</div>
                    <div className="value">{formatTao(validatorData.total_daily_return)} τ</div>
                </StatCell>
                <StatCell>
                    <div className="label">Validator Return</div>
                    <div className="value">{formatTao(validatorData.validator_return)} τ</div>
                </StatCell>
                <StatCell>
                    <div className="label">Validator Take</div>
                    <div className="value">{formatPercentage(parseFloat(validatorData.take)*100)}%</div>
                </StatCell>
                <StatCell>
                    <div className="label">NOM / 24h / kτ</div>
                    <div className="value">{formatNomReturn(validatorData.nominator_return_per_k)} τ</div>
                </StatCell>
                <StatCell>
                    <div className="label">Pending Emission</div>
                    <div className="value">{formatTao(validatorData.pending_emission)} τ</div>
                </StatCell>
                <StatCell>
                    <div className="label">Blocks Until Next Reward</div>
                    <div className="value">{validatorData.blocks_until_next_reward}</div>
                </StatCell>
                <StatCell>
                    <div className="label">Last Reward Block</div>
                    <div className="value">{validatorData.last_reward_block}</div>
                </StatCell>
            </StatsTable>
        </SubnetDetailContainer>
    );
}

export default Validator;
