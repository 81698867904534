import styled from 'styled-components';

export const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #030d17;
  box-shadow: 1px 1px 5px white;
  padding: 15px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 1030px) {
    padding: 10px 20px;
    justify-content: center;
  }
`;

export const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: 1030px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const StatsWrapper = styled.nav`
  display: flex;
  gap: 15px;
  color: #fff;
  width: 100%;
  margin-top: 10px;
  align-items: center;

  h3 {
    font-size: 16px;

    span {
      color: gray;
      font-size: 14px;
    }
  }

  @media (max-width: 768px) {
    dispay : block;
    h3 {
      font-size: 14px;

      span {
        font-size: 12px;
      }
    }
  }
`;
