import React from 'react'
import { HomeContainer } from './home.styles'
import Introduction from '../../components/Introduction/Introduction'
import BitneuronToken from '../../components/BitneuronToken/BitneuronToken'
import Benefits from '../../components/Benefits/Benefits'
import Stake from '../../components/Stake/Stake'
import AIChange from '../../components/AIChange/AIChange'
import Cortext from '../../components/Cortext/Cortext'
import Introduction2 from '../../components/Introduction/Introduction2'

export default function Home() {
  console.log("first")
  return (
    <HomeContainer>
      <Introduction />
      <Introduction2/>
      <Cortext />
      <BitneuronToken />
      <AIChange />
      <Benefits />
      <Stake />
    </HomeContainer>
  )
}
