import styled from "styled-components";
import footerBg from '../../assets/images/footer-bg.png';

export const FooterSection = styled.div`
  background-image: url(${footerBg});
  padding: 2rem 0;

  p {
    display: inline-block;
  }

  a {
    display: inline-block;
  }

  @media (max-width: 800px) {
    padding: 1rem 0;
    gap: 1rem;
  }

  @media (max-width: 450px) {
    padding: 0.5rem 0;
    gap: 0.5rem;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (max-width: 768px) {
    gap: 1rem;
  }
`;

export const FooterContact = styled.div`
  display: flex;
  padding-left: 80px;

  @media (max-width: 1024px) {
    padding-left: 40px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding-left: 20px;
  }

  @media (max-width: 480px) {
    padding-left: 10px;
  }
`;

export const FooterLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
  }
`;

export const FooterForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  input {
    background: transparent;
    border: 1px solid #177BD9;
    width: 200px;
    height: 35px;
    border-radius: 8px;
    padding: 0.5rem;

    @media (max-width: 768px) {
      width: 150px;
    }

    @media (max-width: 480px) {
      width: 120px;
    }
  }
`;

export const FooterLinksPages = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  color: white;
  width: 50%;
  margin-left: 8%;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-left: 0;
    align-items: center;
    width: 100%;
  }

  li {
    margin: 0 10px;

    a {
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const FooterSeparator = styled.img`
  width: 100%;
  height: auto;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const FooterIconsSecurity = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  color: white;
  width: 50%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  li {
    margin: 0 10px;

    a {
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const FooterIcons = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 11rem;

  @media (max-width: 1024px) {
    gap: 5rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
`;

export const FooterIconsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 768px) {
    gap: 1rem;
  }
`;
