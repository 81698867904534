import React from 'react';
import { Link } from 'react-router-dom';
import useSubnetData from '../../store';
import "./DropDownLinks.css";

const DropDownLinks = ({ title, isOpen, setIsOpen, setClose }) => {
  const { subnetData } = useSubnetData();

  // Use subnet names if available, otherwise use a default list
  const data = subnetData.length > 0
    ? subnetData.map(subnet => subnet.name)
    : subnetData.length > 0
    ? subnetData.map(subnet => subnet.netuid)
    : [];

  const ids = subnetData.length > 0
    ? subnetData.map(subnet => subnet.netuid)
    : [];

  const toggleDropdown = () => {
    if (!isOpen) {
      setClose(false); // Close the other dropdown
      setIsOpen(true); // Open this dropdown
    } else {
      setIsOpen(false); // Close this dropdown
    }
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <div className="dropdown-container">
      <button onClick={toggleDropdown} className="dropdown-button-links">
        {title || "Dropdown"}
        <span className="dropdown-icon">{isOpen ? "▲" : "▼"}</span>
      </button>
      {isOpen && (
        <div className="dropdown-menu-list">
          <div className="dropdown-grid">
            {data.map((item, index) => (
              <Link
                key={ids[index]}
                to={`/subnet/${ids[index]}`}
                className="dropdown-item"
                onClick={handleLinkClick}
              >
                {ids[index]}: {item}
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDownLinks;
