import BinanceLogo from './Binance_logo.png';
import BitgetLogo from './bitget-logo.png';
import GateIoLogo from './gate.io-logo.png';
import KucoinLogo from './kucoin-logo.png';
import MexcLogo from './mexc-logo.png';
import TensorLogo from './tensor-logo.png';
import UniswapLogo from './uniswap.png';

const logos = [
  {
    src: MexcLogo,
    href: 'https://www.mexc.co/fr-FR/register',
    color: '#fff',
    name: 'MEXC', // MEXC's blue-green color
  },
  {
    name: 'Binance',
    src: BinanceLogo,
    href: 'https://accounts.binance.com/en/register?ref=JCEYWLC3',
    color: '#0e0e0e', // Binance's yellow color
  },
  {
    name: 'KuCoin',
    src: KucoinLogo,
    href: 'https://www.kucoin.com/fr/ucenter/signup?backUrl=https%3A%2F%2Fwww.kucoin.com%2Ffr%2F404',
    color: '#0093dd', // KuCoin's green color
  },
  {
    name: 'Gate.io',
    src: GateIoLogo,
    href: 'https://www.gate.io/fr/trade/TAO_USDT?ref=A1QSXVla&ref_type=106',
    color: '#fff', // Gate.io's red color
  },
  {
    name: 'Bitget',
    src: BitgetLogo,
    href: 'https://www.bitget.com/expressly?channelCode=281a&vipCode=h69d&languageType=0',
    color: '#00F0FF', // Bitget's blue color
  },

  {
    name: 'Tensor Exchange',
    src: TensorLogo,
    href: 'https://tensor.exchange',
    color: '#fff', // Tensor's black color
  },
  {
    name: 'Uniswap (Wtao)',
    src: UniswapLogo,
    href: 'https://app.uniswap.org/swap',
    color: '#FF007A', // Uniswap's pink color
  },
  {
    name: 'Uniswap (Sttao)',
    src: UniswapLogo,
    href: 'https://app.uniswap.org/swap',
    color: '#FF007A', // Uniswap's pink color
  },
]

export default logos;
